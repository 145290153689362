<template>
  <v-container>
    <v-row align="center" justify="space-around">
      <div class="text--headline">
        Root not found.
        <span class="grey--text">Redirecting...</span>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("entityActions", ["setDefaultRoute"]),
    ...mapActions("auth", ["signOut"]),
  },
  mounted() {
    setTimeout(() => {
      this.signOut();
    }, 3000);
  }
};
</script>
